class PrimaryNavigation {
  constructor() {
    this.dropdown = null;
    this.levelTwoMenus = document.querySelectorAll('.product-dropdown .menu-level-2.has-children');

    this.levelTwoMenus.forEach(element => {
      // Handle click events
      element.addEventListener('click', handleInteraction);

      // Handle keyboard events (Enter and Space)
      element.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault(); // Prevent page scroll on space
          handleInteraction(event);
        }
      });

      // Shared function for both mouse and keyboard interaction
      function handleInteraction(event) {
        event.stopImmediatePropagation();

        const target = event.currentTarget; // Use currentTarget instead of target for consistency
        const targetParent = target.closest('.product-dropdown');

        const allLevelTwoMenus = targetParent.querySelectorAll('.menu-level-2');
        const allSubmenus = targetParent.querySelectorAll('.list-level-3');

        // Reset active states
        if (allLevelTwoMenus) {
          allLevelTwoMenus.forEach(element => {
            element.classList.remove('active');
            element.setAttribute('aria-expanded', 'false');
          });
        }

        if (allSubmenus) {
          allSubmenus.forEach(element => {
            element.classList.remove('active');
            element.setAttribute('aria-hidden', 'true');
          });
        }

        // Set active state for the clicked/activated element
        if (target.dataset.levelTwoId) {
          const subMenu = document.querySelector(`[data-level-three-parent-id="${target.dataset.levelTwoId}"]`);
          if (subMenu) {
            subMenu.classList.add('active');
            subMenu.setAttribute('aria-hidden', 'false');

            target.classList.add('active');
            target.setAttribute('aria-expanded', 'true');

            // Focus the first focusable element in the submenu (for keyboard navigation)
            setTimeout(() => {
              const firstFocusable = getVisibleFocusableElements(subMenu)[0];
              if (firstFocusable) {
                firstFocusable.focus();
              }
            }, 10);
          }
        }
      }
    });
  }

  showDropDown({ target }) {
    // Try setting the target as the dropdown.
    this.dropdown = target.closest('[data-dropdown]');

    // Set dropdown from from target id if no dropdown yet exists.
    if (!this.dropdown) {
      this.dropdown = document.querySelector('[data-dropdown="' + target.dataset.dropdownTarget + '"]');
    }

    // Toggle dropdowns if dropdown is set.
    if (this.dropdown) {
      this.toggleDropDowns();
    }
  }

  hideDropDown({ toElement }) {
    // Try setting the dropdown.
    // No data-dropdown attributes means the dropdown is no longer hovered.
    this.dropdown = toElement.closest('[data-dropdown-target], [data-dropdown]');

    // Delay hiding the dropdowns.
    setTimeout(this.toggleDropDowns.bind(this), 1000);
  }

  toggleDropDowns() {
    document.querySelectorAll('[data-dropdown]').forEach(dropdown => {
      if (dropdown !== this.dropdown) {
        dropdown.classList.add('hidden');
      }
      else {
        dropdown.classList.remove('hidden');
      }
    });
  }

}

document.body.addEventListener('click', (event) => {
  if (!event.target.closest('.product-dropdown') && !event.target.closest('.product-dropdown-button')) {
    // Close all dropdowns
    document.querySelectorAll('.product-dropdown').forEach(dropdown => {
      dropdown.style.display = 'none';
    });
  }
});

// Replace the problematic line with this more precise selector function
const getVisibleFocusableElements = (container) => {
  // First get all potentially focusable elements
  const candidates = Array.from(container.querySelectorAll(
    'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
  ));

  // Then filter to only include visible elements
  return candidates.filter(el => {
    // Check if the element itself is visible
    if (el.offsetParent === null) return false;

    // Check if element has visibility:hidden or display:none
    const style = window.getComputedStyle(el);
    if (style.visibility === 'hidden' || style.display === 'none') return false;

    // Check if any ancestor has display:none or visibility:hidden
    let parent = el.parentElement;
    while (parent !== null) {
      const parentStyle = window.getComputedStyle(parent);
      if (parentStyle.display === 'none' || parentStyle.visibility === 'hidden') {
        return false;
      }
      parent = parent.parentElement;
    }

    // Check for disabled attribute and aria-hidden
    if (el.disabled || el.getAttribute('aria-hidden') === 'true') return false;

    return true;
  });
};

document.querySelectorAll('.product-dropdown-button').forEach((button, index, allButtons) => {
  // Handle click events
  button.addEventListener('click', toggleDropdown);

  // Handle keyboard events (Enter and Space)
  button.addEventListener('keydown', (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault(); // Prevent page scroll on space
      toggleDropdown();
    }
  });

  // Extracted toggle function to be used by both event listeners
  function toggleDropdown() {
    const dropdownId = button.getAttribute('data-dropdown-target');
    const dropdown = document.querySelector(`[data-dropdown="${dropdownId}"]`);

    if (dropdown) {
      // Check if the dropdown is already displayed
      const isDisplayed = dropdown.style.display === 'block';

      // Close all dropdowns
      document.querySelectorAll('.product-dropdown').forEach(dropdown => {
        dropdown.style.display = 'none';
      });

      // Toggle the display of the clicked dropdown
      dropdown.style.display = isDisplayed ? 'none' : 'block';

      // If opening, set focus on the first focusable element
      if (!isDisplayed) {
        // Set ARIA expanded state
        button.setAttribute('aria-expanded', 'true');

        // Focus first interactive element in dropdown
        setTimeout(() => {
          const focusableElements = getVisibleFocusableElements(dropdown);
          const firstFocusable = focusableElements[0];

          if (firstFocusable) {
            firstFocusable.focus();
          }

          // Find the next navigation item after this dropdown button
          const nextNavItem = allButtons[index + 1] ||
                              document.querySelector('.main-navigation a:not(.product-dropdown-button)');

          // Add focusout handler to the last focusable element
          if (focusableElements.length > 0) {
            const lastFocusable = focusableElements[focusableElements.length - 1];

            // Remove any existing event listener first to prevent duplicates
            lastFocusable.removeEventListener('focusout', handleLastElementFocusOut);

            // Add the event listener
            lastFocusable.addEventListener('focusout', handleLastElementFocusOut);

            function handleLastElementFocusOut(e) {
              // When focus leaves the last element, we need to check if it's going
              // to an element outside the dropdown
              setTimeout(() => {
                console.log('leaving')
                // If the new activeElement is not within the dropdown
                if (!dropdown.contains(document.activeElement) &&
                    document.activeElement !== button) {

                  // If tabbing forward, focus the next navigation item
                  const activeButton = document.querySelector('.product-dropdown-button[aria-expanded="true"]');
                  if (activeButton) {
                    activeButton.focus();
                  }

                  // Close the dropdown since focus has left it
                  dropdown.style.display = 'none';
                  button.setAttribute('aria-expanded', 'false');
                }
              }, 0);
            }
          }
        }, 100);
      } else {
        // Reset ARIA expanded state
        button.setAttribute('aria-expanded', 'false');
        // Return focus to the button when closing
        button.focus();
      }
    }
  }
});

// Add keyboard trap within dropdown for better accessibility
document.addEventListener('keydown', function(e) {
  // Find any visible dropdown
  const visibleDropdown = document.querySelector('.product-dropdown[style*="display: block"]');

  if (visibleDropdown) {
    const focusableElements = getVisibleFocusableElements(visibleDropdown);

    // Only proceed if we have focusable elements
    if (focusableElements.length > 0) {
      const firstFocusable = focusableElements[0];
      const lastFocusable = focusableElements[focusableElements.length - 1];
      const dropdownButton = document.querySelector(`.product-dropdown-button[data-dropdown-target="${visibleDropdown.getAttribute('data-dropdown')}"]`);

      // Handle Tab and Shift+Tab to create a focus trap
      if (e.key === 'Tab') {
        // If at last element and tabbing forward, let our focusout handler take care of it
        if (!e.shiftKey && document.activeElement === lastFocusable) {
          // Don't prevent default - let natural tabbing occur and our focusout handler will redirect
          e.preventDefault();
          dropdownButton.focus();
          // Close the dropdown when focus returns to button
          visibleDropdown.style.display = 'none';
          dropdownButton.setAttribute('aria-expanded', 'false');
        }
        // If at first element and tabbing backward, go to the trigger button
        else if (e.shiftKey && document.activeElement === firstFocusable) {
          e.preventDefault();
          dropdownButton.focus();
          // Close the dropdown when focus returns to button
          visibleDropdown.style.display = 'none';
          dropdownButton.setAttribute('aria-expanded', 'false');
        }
      }
    }
  }
});

// Keep the existing Escape key handler
document.body.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') {
    // Close all dropdowns on Escape key
    document.querySelectorAll('.product-dropdown').forEach(dropdown => {
      dropdown.style.display = 'none';
    });

    // Return focus to the active dropdown button if there was one
    const activeButton = document.querySelector('.product-dropdown-button[aria-expanded="true"]');
    if (activeButton) {
      activeButton.focus();
    }

    // Reset all aria-expanded states
    document.querySelectorAll('.product-dropdown-button').forEach(button => {
      button.setAttribute('aria-expanded', 'false');
    });
  }
});

export default function () {
  new PrimaryNavigation();
}
