import { render, staticRenderFns } from "./SearchPageItem.vue?vue&type=template&id=68b3d428&"
import script from "./SearchPageItem.vue?vue&type=script&lang=js&"
export * from "./SearchPageItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('68b3d428', component.options)
    } else {
      api.reload('68b3d428', component.options)
    }
    module.hot.accept("./SearchPageItem.vue?vue&type=template&id=68b3d428&", function () {
      api.rerender('68b3d428', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/SearchPageItem.vue"
export default component.exports