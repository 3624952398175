let currentScrollPosition = 0;
let isLocked = false;

const html = document.getElementsByTagName('html')[0];
const lockTriggers = document.getElementsByClassName('lockTrigger');

for (let i = 0; i < lockTriggers.length; i += 1) {
  lockTriggers[i].addEventListener('click', () => {
    if (isLocked === false) {
      currentScrollPosition = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
      setTimeout(function() {
        html.style.scrollBehavior = 'unset';
        document.body.classList.add('lock-screen');
      }, 300);
      isLocked = true;
    } else {
      document.body.classList.remove('lock-screen');
      document.body.scrollTop = document.documentElement.scrollTop = currentScrollPosition;
      html.style.scrollBehavior = 'smooth';
      isLocked = false;
    }
  });
}
