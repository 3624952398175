// We import our styles here, webpack will do the rest
import styles from '../scss/app.scss'

import '@babel/polyfill';
// lazy sizes for lazy loading images
import Lazysizes from 'lazysizes'
import _ from 'lodash'
import 'picturefill'

// Our own helpers
import './helpers/classList'
import './helpers/iePolyfills'
import './helpers/objectfitFallback'
import './components/toggle'
import './components/headroom'
import './components/carousel'
import './components/lockTrigger'
import './components/preventZoom'
import reframe from './helpers/iframe'

// Our own components
import primaryNavigation from './components/primaryNavigation'
import footerNavigation from './components/footerNavigation'
import './components/slideshow'

import './components/searchCommon';
import './components/generalSearch';
import './components/searchPageSearch';
import './components/officeSearch';
import './components/modal';

import tabs from './components/tabs';

document.addEventListener('lazybeforeunveil', function (e) {
  if (e.target.tagName === 'IFRAME') {
    reframe(e.target);
  }
});

// Window onload (after all dom and images are loaded completely)
window.onload = function () {
  if (location.hash) {
    let elId = location.hash.replace('#', '');
    let scrollToEl = document.getElementById(elId);

    const yCoordinate = scrollToEl.getBoundingClientRect().top + window.pageYOffset;
    let myElement = document.querySelector("#primary-navigation");

    const yOffset = 0 - myElement.offsetHeight;

    window.scrollTo({
      top: yCoordinate + yOffset,
      behavior: 'smooth'
    });
  }

  // Page has loaded, remove loading class
  document.getElementsByTagName('body')[0].classList.remove('loading')

  // Responsive tables
  // wrap tables in wysiwyg in a div for styling
  const tables = document.querySelectorAll('.wysiwyg table')
  if (tables.length) {
    if (!('length' in tables)) tables = [tables]
    for (let i = 0; i < tables.length; i += 1) {
      const table = tables[i]

      const div = document.createElement('div')
      div.className = 'table-container'

      table.parentNode.insertBefore(div, table)
      table.parentNode.removeChild(table)
      div.appendChild(table)
    }
  }

}

function replaceYouTubeNocookie() {
  // Get all iframe elements on the page
  var iframes = document.getElementsByTagName('iframe');

  if (iframes) {
    // Loop through each iframe
    for (var i = 0; i < iframes.length; i++) {
      var iframe = iframes[i];

      // List of attributes to check
      var attrs = ['src', 'data-src'];

      // Loop through each attribute
      for (var j = 0; j < attrs.length; j++) {
        var attr = attrs[j];
        var src = iframe.getAttribute(attr);

        if (src && src.includes("youtube.com")) {
          // Replace "youtube.com" with "youtube-nocookie.com"
          var newSrc = src.replace("youtube.com", "youtube-nocookie.com");
          iframe.setAttribute(attr, newSrc);
        }
      }
    }
  }
}

replaceYouTubeNocookie();
primaryNavigation();
footerNavigation();
tabs();

const dropdownFunction = (toggleButtonSelector, dropdownSelector, closeButtonSelector, focusOnElement) => {
  const toggleButtons = document.querySelectorAll(toggleButtonSelector);
  const dropdown = document.querySelector(dropdownSelector);

  // Add ARIA attributes for accessibility
  if (dropdown && toggleButtons.length) {
    // Set initial ARIA attributes
    dropdown.setAttribute('aria-hidden', 'true');
    toggleButtons.forEach(button => {
      // button.setAttribute('aria-expanded', 'false');
      button.setAttribute('aria-haspopup', 'true');

      // Connect button to dropdown with aria-controls
      const dropdownId = dropdownSelector.startsWith('#') ?
        dropdownSelector.substring(1) :
        `dropdown-${Math.random().toString(36).substring(2, 9)}`;

      if (!dropdown.id) {
        dropdown.id = dropdownId;
      }

      button.setAttribute('aria-controls', dropdown.id);
    });
  }

  // Helper function to get focusable elements
  const getFocusableElements = container => {
    return Array.from(container.querySelectorAll(
      'a[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
    )).filter(el => {
      const style = window.getComputedStyle(el);
      return style.display !== 'none' && style.visibility !== 'hidden' && !el.disabled;
    });
  };

  // Function to check if element is part of dropdown or toggle buttons
  const isPartOfDropdown = (element) => {
    if (!element) return false;

    // Check if element is one of the toggle buttons
    if (Array.from(toggleButtons).some(button => button === element || button.contains(element))) {
      return true;
    }

    // Check if element is inside the dropdown
    return dropdown.contains(element);
  };

  // Handler for when focus leaves an element
  const handleFocusOut = (event) => {
    // Use setTimeout to ensure we're checking after focus has moved
    setTimeout(() => {
      // If neither the dropdown nor toggle buttons contain the active element, close the dropdown
      if (!isPartOfDropdown(document.activeElement)) {
        closeDropdown();
      }
    }, 0);
  };

  const toggleDropdown = (event) => {
    if (!dropdown) return;

    if (dropdown.style.display === 'block') {
      // Close dropdown
      closeDropdown();
    } else {
      // Open dropdown
      dropdown.style.display = 'block';
      dropdown.setAttribute('aria-hidden', 'false');

      // Set ARIA expanded on all toggle buttons
      toggleButtons.forEach(button => {
        // button.setAttribute('aria-expanded', 'true');
      });

      // Add global event listeners
      document.addEventListener('click', handleOutsideClick);
      document.addEventListener('keydown', handleKeyDown);

      // Add focusout listener to the dropdown container
      dropdown.addEventListener('focusout', handleFocusOut);

      // Also add focusout to each toggle button
      toggleButtons.forEach(button => {
        button.addEventListener('focusout', handleFocusOut);
      });

      // Focus first interactive element in dropdown
      if (dropdownSelector === '#search-dropdown') {
        document.getElementById('dropdown-search').focus();
      } else {
        setTimeout(() => {
          if (focusOnElement) {
            const focusElement = dropdown.querySelector(focusOnElement);
            if (focusElement) {
              focusElement.focus();
            }
          }
          else {
            const focusableElements = getFocusableElements(dropdown);
            if (focusableElements.length > 0) {
              focusableElements[0].focus();
            }
          }
        }, 10);
      }
    }
  };

  const closeDropdown = () => {
    if (!dropdown || dropdown.style.display !== 'block') return;

    dropdown.style.display = "none";
    dropdown.setAttribute('aria-hidden', 'true');

    // Reset ARIA expanded on all toggle buttons
    toggleButtons.forEach(button => {
      // button.setAttribute('aria-expanded', 'false');
    });

    // Remove all event listeners
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('keydown', handleKeyDown);
    dropdown.removeEventListener('focusout', handleFocusOut);

    toggleButtons.forEach(button => {
      button.removeEventListener('focusout', handleFocusOut);
    });

    // Return focus to the last clicked button
    if (lastFocusedButton) {
      lastFocusedButton.focus();
    }

    // Ensure lock screen doesn't stick.
    document.body.classList.remove('lock-screen');
  };

  const handleOutsideClick = (event) => {
    if (event.target.closest(toggleButtonSelector) || event.target.classList.contains('preventClose')) return;
    if (!event.target.closest(dropdownSelector) || !!event.target.closest(closeButtonSelector)) {
      closeDropdown();
    }
  };

  const handleKeyDown = (event) => {
    // Close dropdown on Escape
    if (event.key === 'Escape') {
      closeDropdown();
      return;
    }

    // Handle tab navigation inside dropdown
    if (event.key === 'Tab' && dropdown.style.display === 'block') {
      const focusableElements = getFocusableElements(dropdown);

      if (focusableElements.length === 0) return;

      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      // If tabbing backward from first element, close dropdown and focus toggle button
      if (event.shiftKey && document.activeElement === firstElement) {
        event.preventDefault();
        closeDropdown();
        return;
      }

      // If tabbing forward from last element, close dropdown and let natural tab order take over
      if (!event.shiftKey && document.activeElement === lastElement) {
        closeDropdown();
        // Don't prevent default so focus moves naturally to next element
      }
    }
  };

  // Track which button was last clicked/focused
  let lastFocusedButton = null;

  // Add event listeners to toggle buttons
  toggleButtons.forEach(toggleButton => {
    // Click event
    toggleButton.addEventListener('click', (event) => {
      lastFocusedButton = toggleButton;
      toggleDropdown(event);
    });

    // Keyboard events (Enter and Space)
    toggleButton.addEventListener('keydown', (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault(); // Prevent page scroll on Space
        lastFocusedButton = toggleButton;
        toggleDropdown(event);
      }
    });
  });

  // Close button inside dropdown (if any)
  const closeButtons = dropdown ? dropdown.querySelectorAll(closeButtonSelector) : [];
  if (closeButtons.length > 0) {
    closeButtons.forEach(closeButton => {
      closeButton.addEventListener('click', closeDropdown);
      closeButton.addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          closeDropdown();
        }
      });
    });
  }
};

// USER DROPDOWN
dropdownFunction('#user-dropdown-button', '#user-dropdown', null, null);
// REGION DROPDOWN
dropdownFunction('#region-dropdown-button', '.region-dropdown', null, null);
// MOBILE NAV
dropdownFunction('#hamburger', '#nav-dropdown', '#nav-close', null);
// HEADER SEARCH
dropdownFunction('.toggle-nav-dropdown', '#nav-dropdown', null, '#dropdown-search');
